import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import './Builds.css'
import { BuildContext, CompareBuildContext } from '../../contexts/Context'

function Builds() {
  interface Option {
    value: string
    label: string
  }
  const [activeBuild, setActiveBuild] = useContext(BuildContext)
  const [, setCompareBuild] = useContext(CompareBuildContext)
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)

  useEffect(() => {
    const host = process.env.REACT_APP_API_URL
    fetch(host + 'builds')
      .then((response) => response.json())
      .then((data) => {
        let dropDownList = []
        for (const build in data) {
          const date = new Date(data[build].timestamp).toLocaleString()
          dropDownList.push({
            value: data[build].buildguid,
            label: '' + data[build].buildnumber + ' Time: ' + date,
          })
        }
        setDropdownOptions(dropDownList)

        const activeBuildEntry = dropDownList.find((option) => option.value === activeBuild)
        if (activeBuildEntry) {
          setSelectedOption(activeBuildEntry)
        } else {
          setActiveBuild(dropDownList[0].value)
          setSelectedOption(dropDownList[0])
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [setActiveBuild, activeBuild])

  const updateBuild = (selected: Option | null) => {
    if (!selected) {
      return
    }
    setSelectedOption(selected)
    setActiveBuild(selected.value)
  }

  // Compare build stuff
  const updateCompareBuild = (selected: Option | null) => {
    setCompareBuild(selected ? selected.value : '')
  }

  return (
    <>
      <Select
        className="builds-select-main"
        styles={{
          input: (styles) => ({ ...styles, color: '#cad1d8' }),
          placeholder: (styles) => ({ ...styles, color: '#cfd1d3' }),
          singleValue: (styles) => ({ ...styles, color: '#cad1d8' }),
          control: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
            borderColor: '#333',
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            color: '#cad1d8',
            backgroundColor: isSelected ? '#31363c' : isFocused ? '#31363c' : '#22262c',
            ':active': {
              backgroundColor: isSelected ? '#31363c' : '#31363c',
            },
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
          menuList: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
        }}
        isClearable={false}
        isSearchable={true}
        onChange={updateBuild}
        options={dropdownOptions}
        defaultValue={dropdownOptions[0]}
        value={selectedOption}
      />
      <Select
        className="builds-select-main"
        styles={{
          input: (styles) => ({ ...styles, color: '#cad1d8' }),
          placeholder: (styles) => ({ ...styles, color: '#cfd1d3' }),
          singleValue: (styles) => ({ ...styles, color: '#cad1d8' }),
          control: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
            borderColor: '#333',
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            color: '#cad1d8',
            backgroundColor: isSelected ? '#31363c' : isFocused ? '#31363c' : '#22262c',
            ':active': {
              backgroundColor: isSelected ? '#31363c' : '#31363c',
            },
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
          menuList: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
        }}
        isClearable={true}
        isSearchable={true}
        onChange={updateCompareBuild}
        options={dropdownOptions}
        placeholder="Select a build to compare"
      />
    </>
  )
}

export default Builds
