import React, { useState } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Box,
  Collapse,
} from '@mui/material'

import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

type PrivateAura = {
  id: number
  name_lang: string
}

type PrivateAuras = PrivateAura[]

const wowheadDomain = 'ptr-2'

function Row({ boss, location, auras }: { boss: string; location: string; auras: PrivateAura[] }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {auras.length}
        </TableCell>
        <TableCell component="th" scope="row">
          {boss}
        </TableCell>
        <TableCell component="th" scope="row">
          {location}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>SpellId</TableCell>
                    <TableCell>wowhead link/tooltip</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auras.map((aura) => (
                    <TableRow key={aura.id}>
                      <TableCell component="th" scope="row" sx={{ width: '50px' }}>
                        {aura.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <a
                          href={`https://wowhead.com/${wowheadDomain}/spell=${aura.id}`}
                          data-wowhead={`"spell=${aura.id}&domain=${wowheadDomain}"`}
                          target="_blank"
                          rel="noreferrer"
                          data-wh-rename-link="true"
                        >
                          {aura.name_lang}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const knownAuras: { [key: number]: [string, string, boolean?] } = {
  // Other
  410317: ['n/a', 'Testing', true],
  410326: ['n/a', 'Testing', true],
  // M+
  417938: ['M+', '5-Man', true],
  426865: ['M+', '5-Man', true],
  453278: ['M+', '5-Man', true],
  420696: ['M+', '5-Man', true],
  421250: ['M+', '5-Man', true],
  450855: ['M+', '5-Man', true],
  434090: ['M+', '5-Man', true],
  434406: ['M+', '5-Man', true],
  423080: ['M+', '5-Man', true],
  468811: ['M+', '5-Man', true],
  // Amirdrassil, the Dream's Hope
  418589: ['Council of Dreams', "Amirdrassil, the Dream's Hope", true],
  429123: ['Council of Dreams', "Amirdrassil, the Dream's Hope", true],
  414187: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  419060: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  421825: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  421826: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  421827: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  421828: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  421829: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  422520: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  423601: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  425525: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  426370: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  428970: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  428988: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  430048: ['Fyrakk the Blazing', "Amirdrassil, the Dream's Hope", true],
  427722: ['Nymue, Weaver of the Cycle', "Amirdrassil, the Dream's Hope", true],
  426010: ['Smolderon', "Amirdrassil, the Dream's Hope", true],
  420544: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  420545: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  421461: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  425468: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  425469: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  425596: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  425888: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  425962: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  425963: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  425964: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  426161: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  428901: ['Larodar, Keeper of the Flame', "Amirdrassil, the Dream's Hope", true],
  // Aberrus, the Shadowed Crucible
  406317: ['The Forgotten Experiments', 'Aberrus, the Shadowed Crucible', true],
  407182: ['Echo of Neltharion', 'Aberrus, the Shadowed Crucible', true],
  410966: ['Echo of Neltharion', 'Aberrus, the Shadowed Crucible', true],
  // Nerub-ar Palace
  433517: ['Sikran, Captain of the Sureki', 'Nerub-ar Palace', true],
  454721: ['Sikran, Captain of the Sureki', 'Nerub-ar Palace', true],
  439191: ['Sikran, Captain of the Sureki', 'Nerub-ar Palace', true],
  439790: ["Rasha'nan", 'Nerub-ar Palace', true],
  439783: ["Rasha'nan", 'Nerub-ar Palace', true],
  439815: ["Rasha'nan", 'Nerub-ar Palace', true],
  463273: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  463276: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  438141: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  435534: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  436663: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  436664: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  436665: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  436666: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  436671: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  436677: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  436870: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  441952: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  470503: ["Nexus-Princess Ky'veza", 'Nerub-ar Palace', true],
  459669: ['Vexie and the Geargrinders', 'Undermine'],
  465325: ['The One-Armed Bandit', 'Undermine'],
  472354: ["Mug'Zee, Heads of Security", 'Undermine'],
  466155: ['Chrome King Gallywix', 'Undermine'],
  466344: ['Chrome King Gallywix', 'Undermine'],
  1214749: ['Chrome King Gallywix', 'Undermine'],
  1214750: ['Chrome King Gallywix', 'Undermine'],
  1214757: ['Chrome King Gallywix', 'Undermine'],
  1214758: ['Chrome King Gallywix', 'Undermine'],
  1214759: ['Chrome King Gallywix', 'Undermine'],
  1214760: ['Chrome King Gallywix', 'Undermine'],
  1214761: ['Chrome King Gallywix', 'Undermine'],
  1214762: ['Chrome King Gallywix', 'Undermine'],
  1214763: ['Chrome King Gallywix', 'Undermine'],
  1214764: ['Chrome King Gallywix', 'Undermine'],
  1214765: ['Chrome King Gallywix', 'Undermine'],
  1214766: ['Chrome King Gallywix', 'Undermine'],
  1214767: ['Chrome King Gallywix', 'Undermine'],
  1219279: ['Chrome King Gallywix', 'Undermine'],
}

function SortPrivateAuras(privateAuras: PrivateAuras, showOldContent: boolean) {
  const sortedAuras: {
    [key: string]: { auras: PrivateAura[]; location: string; oldContent?: boolean }
  } = { Unknown: { auras: [], location: '' } }
  const knownAurasTEMP = { ...knownAuras }
  privateAuras.forEach((aura) => {
    const boss = knownAuras[aura.id]
    if (boss) {
      if (showOldContent || !boss[2]) {
        if (!sortedAuras[boss[0]]) {
          sortedAuras[boss[0]] = { auras: [], location: boss[1] }
        }
        sortedAuras[boss[0]].auras.push(aura)
      }
    } else {
      sortedAuras['Unknown'].auras.push(aura)
    }
    delete knownAurasTEMP[aura.id]
  })

  if (Object.keys(knownAurasTEMP).length > 0) {
    sortedAuras['NOT PRIVATE ANYMORE'] = { auras: [], location: 'NOT PRIVATE' }
    Object.keys(knownAurasTEMP).forEach((auraId) => {
      const aura = knownAurasTEMP[Number(auraId)]
      if (aura) {
        sortedAuras['NOT PRIVATE ANYMORE'].auras.push({ id: parseInt(auraId), name_lang: `${auraId}` })
      }
    })
  }

  return sortedAuras
}

export function PrivateAurasTable({
  privateAuras,
  showOldContent,
}: {
  privateAuras: PrivateAuras
  showOldContent: boolean
}) {
  const [sortField, setSortField] = useState<'boss' | 'location' | 'totalAuras'>('boss')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  const sortedAuras = SortPrivateAuras(privateAuras, showOldContent)
  const sortedBosses = Object.keys(sortedAuras).sort((a, b) => {
    if (sortField === 'boss') {
      return sortDirection === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
    } else if (sortField === 'location') {
      return sortDirection === 'asc'
        ? sortedAuras[a].location.localeCompare(sortedAuras[b].location)
        : sortedAuras[b].location.localeCompare(sortedAuras[a].location)
    } else {
      // sortField === 'totalAuras'
      return sortDirection === 'asc'
        ? sortedAuras[a].auras.length - sortedAuras[b].auras.length
        : sortedAuras[b].auras.length - sortedAuras[a].auras.length
    }
  })

  const handleSort = (field: 'boss' | 'location' | 'totalAuras') => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }
  return (
    <TableContainer component={Paper} sx={{ width: '50%' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '30px' }} />
            <TableCell
              align="center"
              onClick={() => handleSort('totalAuras')}
              sx={{ cursor: 'pointer', width: '70px' }}
            >
              <Box display="flex" alignItems="center">
                {sortField === 'totalAuras' &&
                  (sortDirection === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)}
                Auras
              </Box>
            </TableCell>
            <TableCell onClick={() => handleSort('boss')} sx={{ cursor: 'pointer', minWidth: '50px', mr: '10px' }}>
              <Box display="flex" alignItems="center">
                {sortField === 'boss' &&
                  (sortDirection === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)}
                Encounter
              </Box>
            </TableCell>
            <TableCell onClick={() => handleSort('location')} sx={{ cursor: 'pointer' }}>
              <Box display="flex" alignItems="center">
                {sortField === 'location' &&
                  (sortDirection === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />)}
                Location
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedBosses.map((boss) => (
            <Row key={boss} boss={boss} location={sortedAuras[boss].location} auras={sortedAuras[boss].auras} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
