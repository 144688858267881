import React, { useState, useEffect, useContext } from 'react'
import { BuildContext, InstanceContext, BossContext, DifficultyContext } from '../../contexts/Context'

function BossMenu() {
  const [activeInstance] = useContext(InstanceContext)
  const [activeBuild] = useContext(BuildContext)
  const [bosses, setBosses] = useState([])
  const [bossId, setBossId] = useContext(BossContext)
  const [, , , setDifficulties] = useContext(DifficultyContext)
  useEffect(() => {
    if (!activeBuild || !activeInstance) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + activeBuild + '/instance/' + activeInstance)
      .then((response) => response.json())
      .then((data) => {
        let bosses = data.bosses
        bosses.sort((a: any, b: any) => a.orderindex - b.orderindex)
        setBosses(bosses)

        let difficulties = data.difficulties
        setDifficulties(difficulties)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [activeInstance, bossId, activeBuild, setDifficulties])

  function BossList(bosses: Boss[]) {
    const clicked = (id: string) => {
      let idToNumber = parseInt(id)
      if (idToNumber === bossId) {
        setBossId(0)
      } else {
        setBossId(idToNumber)
      }
    }
    return bosses.map((boss) => (
      <button
        key={boss.id}
        onClick={() => clicked(boss.id)}
        className={`bossButtons ${parseInt(boss.id) === bossId ? 'active' : ''}`}
      >
        {boss.name_lang}
      </button>
    ))
  }

  return <div id="bosses">{Array.isArray(bosses) && BossList(bosses)}</div>
}

export default BossMenu
