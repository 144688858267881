import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Zoom from '@mui/material/Zoom'
import './SpellTags.css'

interface ExtraSpellTagInfo {
  [key: string]: string
}

const extraSpellTagInfo: ExtraSpellTagInfo = {
  'Ignore Line of Sight': 'This spell ignores line of sight restrictions. Duh.',
  'No Immunities': 'Pierces Immunities. Ouch.',
  'Private Aura': 'This spell is a private aura. Shhh.',
  'Allow Aura While Dead': 'This spell is allowed to be active while dead. Spooky.',
  'No Reflection': 'This spell cannot be reflected. Like a vampire in the mirror.',
  'Always Hit': 'This spell always hits. What did you expect?',
}

function SpellTags({ tags }: { tags: any[] }) {
  return (
    <ul className="tags">
      {tags.map((tag) => (
        <Tooltip
          key={tag[0]}
          title={
            <React.Fragment>
              <Typography color="inherit">{tag[0]}</Typography>
              {extraSpellTagInfo[tag[0]] ? extraSpellTagInfo[tag[0]] : 'More Info About this Flag?'}
            </React.Fragment>
          }
          placement="top"
          arrow
          TransitionComponent={Zoom}
        >
          <li className={`tag tag_${tag[1]}`}>{tag[0]}</li>
        </Tooltip>
      ))}
    </ul>
  )
}

export default SpellTags
