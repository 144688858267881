import { createContext, Dispatch, SetStateAction } from 'react'

export const BuildContext = createContext<[string, Dispatch<SetStateAction<string>>]>(['', () => {}])
export const CompareBuildContext = createContext<[string, Dispatch<SetStateAction<string>>]>(['', () => {}])
export const ExpansionContext = createContext<[number, Dispatch<SetStateAction<number>>]>([0, () => {}])
export const InstanceContext = createContext<[number, Dispatch<SetStateAction<number>>]>([0, () => {}])
export const DifficultyContext = createContext<
  [number, Dispatch<SetStateAction<number>>, number[], Dispatch<SetStateAction<number[]>>]
>([0, () => {}, [], () => {}])
export const BossContext = createContext<[number, Dispatch<SetStateAction<number>>]>([0, () => {}])
// value={[hiddenSections, toggleHidden]}
export const HiddenSectionsContext = createContext<[HiddenSections, (id: number) => void]>([{}, () => {}])
