import React, { useState, useEffect, useContext } from 'react'
import { BuildContext, BossContext, DifficultyContext, HiddenSectionsContext } from '../../contexts/Context'
import CollapseButton from './CollapseButton'
import Section from './Section/Section'
import DiffView from './DiffView'
import './BossJournal.css'

const journalDefaults = {
  id: 0,
  buildguid: '',
  journalinstanceid: 0,
  dungeonencounterid: 0,
  orderindex: 0,
  name_lang: '',
  description_lang: '',
  firstsectionid: 0,
  journalSection: [],
}

function BossJournal() {
  const [activeBuild] = useContext(BuildContext)
  const [bossId] = useContext(BossContext)
  const [difficulty] = useContext(DifficultyContext)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [journal, setJournal] = useState<Journal>(journalDefaults)

  let storedHiddenSections: HiddenSections = {}
  const item = localStorage.getItem('hiddenSections')
  if (item) {
    try {
      storedHiddenSections = JSON.parse(item) as HiddenSections
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [hiddenSections, setHiddenSections] = useState<HiddenSections>(storedHiddenSections)
  useEffect(() => {
    localStorage.setItem('hiddenSections', JSON.stringify(hiddenSections))
  }, [hiddenSections])

  useEffect(() => {
    if (bossId === 0) {
      setJournal(journalDefaults)
      return
    } else {
      setLoading(true)
    }
    if (activeBuild === '' || activeBuild === undefined) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + activeBuild + '/boss/' + bossId + '/' + difficulty)
      .then((response) => response.json())
      .then((data) => {
        setJournal(data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [bossId, activeBuild, difficulty])

  const toggleHidden = (id: number) => {
    const idString = id.toString()
    setHiddenSections((prevState: { [key: string]: boolean }) => ({
      ...prevState,
      [id]: !prevState[idString],
    }))
  }

  return (
    <HiddenSectionsContext.Provider value={[hiddenSections, toggleHidden]}>
      <div id="journal">
        {isLoading ? (
          <div id="encounterTitle">Loading...</div>
        ) : Array.isArray(journal.journalSection) ? (
          <>
            <div id="encounterTitle">
              <CollapseButton sections={journal.journalSection} />
              <span className="name_lang">{journal.name_lang}</span>
              <span className="encounter_id">({journal.dungeonencounterid})</span>
            </div>
            <Section sections={journal.journalSection} />
          </>
        ) : (
          <div id="encounterTitle">No Boss Selected</div>
        )}
      </div>
      <DiffView isLoading={isLoading} journal={journal} />
    </HiddenSectionsContext.Provider>
  )
}

export default BossJournal
