import React, { useState, useEffect } from 'react'
import {
  BuildContext,
  CompareBuildContext,
  InstanceContext,
  BossContext,
  DifficultyContext,
} from '../../contexts/Context'
import MainMenu from '../../components/MainMenu/MainMenu'
import BossMenu from '../../components/BossMenu/BossMenu'
import BossJournal from '../../components/BossJournal/BossJournal'
import './Journal.css'

function Journal() {
  const [activeBuild, setActiveBuild] = useState<string>('')
  useEffect(() => {
    localStorage.setItem('activeBuild', JSON.stringify(activeBuild))
  }, [activeBuild])

  const [compareBuild, setCompareBuild] = useState<string>('')

  let storedActiveInstance = 1296 // 1273 = Nerub-ar, 1296 = Liberation of Undermine
  const itemActiveInstance = localStorage.getItem('activeInstance')
  if (itemActiveInstance) {
    try {
      storedActiveInstance = JSON.parse(itemActiveInstance)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [activeInstance, setActiveInstance] = useState<number>(Number(storedActiveInstance))
  useEffect(() => {
    localStorage.setItem('activeInstance', JSON.stringify(activeInstance))
  }, [activeInstance])

  let storedBossId = 2639 // Vexie and the Geargrinders
  const itemBossId = localStorage.getItem('bossId')
  if (itemBossId) {
    try {
      storedBossId = JSON.parse(itemBossId)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [bossId, setBossId] = useState<number>(storedBossId)
  useEffect(() => {
    localStorage.setItem('bossId', JSON.stringify(bossId))
  }, [bossId])

  let storedDifficulty = 16
  const itemDifficulty = localStorage.getItem('difficulty')
  if (itemDifficulty) {
    try {
      storedDifficulty = JSON.parse(itemDifficulty)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  let storedDifficulties: number[] = [14, 15, 16, 17]
  const itemDifficulties = localStorage.getItem('difficulties')
  if (itemDifficulties) {
    try {
      storedDifficulties = JSON.parse(itemDifficulties) as number[]
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [difficulty, setDifficulty] = useState<number>(storedDifficulty)
  const [difficulties, setDifficulties] = useState<number[]>(storedDifficulties)

  return (
    <div id="wrapper">
      <BuildContext.Provider value={[activeBuild, setActiveBuild]}>
        <CompareBuildContext.Provider value={[compareBuild, setCompareBuild]}>
          <InstanceContext.Provider value={[activeInstance, setActiveInstance]}>
            <BossContext.Provider value={[bossId, setBossId]}>
              <DifficultyContext.Provider value={[difficulty, setDifficulty, difficulties, setDifficulties]}>
                <MainMenu />
                <BossMenu />
                <BossJournal />
              </DifficultyContext.Provider>
            </BossContext.Provider>
          </InstanceContext.Provider>
        </CompareBuildContext.Provider>
      </BuildContext.Provider>
    </div>
  )
}

export default Journal
