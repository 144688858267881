import React, { ReactElement, useContext } from 'react'
import JournalSectionTitle from './JournalSectionTitle'
import JournalSectionDescription from './JournalSectionDescription'
import { HiddenSectionsContext } from '../../../contexts/Context'

function Section({ sections }: { sections: JournalSection[] }): ReactElement {
  const [hiddenSections] = useContext(HiddenSectionsContext)

  function GetSection(sections: JournalSection[], depth: number = 0, parentHidden: boolean = false): ReactElement {
    if (sections.length === 0 && depth === 0) {
      return <p id="noJournal">This boss has no journal entries.</p>
    }
    depth = depth + 1

    return (
      <>
        {sections.map((section: JournalSection): ReactElement => {
          const hidden = depth === 1 ? false : parentHidden

          return (
            <div
              className={`section ${depth === 1 && 'topSection'} section_${section.type} ${hidden ? 'hidden' : ''}`}
              key={section.id}
            >
              <JournalSectionTitle section={section} parentHidden={hidden} />
              <JournalSectionDescription section={section} />
              {section.child && GetSection(section.child, depth, hiddenSections[section.id] || hidden)}
            </div>
          )
        })}
      </>
    )
  }

  return <>{GetSection(sections)}</>
}

export default Section
