export function GetSectionFlags(flags: any) {
  const flagMap = {
    1: [1, 'Tank'], // 1      Tank
    2: [2, 'Dps'], // 2      Dps
    3: [4, 'Healer'], // 4      Healer
    4: [8, 'Heroic'], // 8      Heroic
    5: [16, 'Deadly'], // 16     Deadly
    6: [32, 'Important'], // 32     Important
    7: [64, 'Interruptable'], // 64     Interruptable
    8: [128, 'Magic'], // 128    Magic
    9: [256, 'Curse'], // 256    Curse
    10: [512, 'Poison'], // 512    Poison
    11: [1024, 'Disease'], // 1024   Disease
    12: [2048, 'Enrage'], // 2048   Enrage
    13: [4096, 'Mythic'], // 4096   Mythic
    14: [8192, 'Bleed'], // 8192   Bleed
  }
  let tags = []
  for (const [, value] of Object.entries(flagMap)) {
    if (flags & Number(value[0])) {
      tags.push(value[1])
    }
  }
  return tags
}
