import '@fontsource/roboto'
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      color: '#ffffff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: '#ffffff',
        },
        background: 'none',
        a: {
          color: '#e51039',
          textDecoration: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: '#e51039',
          }),
        }),
        filled: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '50px',
          backgroundColor: '#e51039',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '50px',
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#000000',
        },
        arrow: {
          color: '#000000',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#e51039',
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#e51039',
          },
        },
      },
    },
  },
})

export default theme
