import React, { useContext } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { GetSectionFlags } from '../../../utils/GetSectionFlags'
import { useSnackbar } from 'notistack'
import { HiddenSectionsContext } from '../../../contexts/Context'

const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.position = 'fixed'
  textArea.style.top = '50%'
  textArea.style.left = '50%'
  textArea.style.transform = 'translate(-50%, -50%)'
  textArea.style.opacity = '0' // Make it invisible
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Unable to copy to clipboard', err)
  }
  document.body.removeChild(textArea)
}

const handleCopyToClipboard = (text: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
  event.stopPropagation() // Prevent the event from propagating to parent elements

  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(text.toString()).catch((err) => {
      console.error('Failed to copy text: ', err)
    })
  } else {
    unsecuredCopyToClipboard(text)
  }
}

function JournalSectionTitle({ section, parentHidden }: { section: any; parentHidden: boolean }) {
  const { enqueueSnackbar } = useSnackbar()
  const [hiddenSections, toggleHidden] = useContext(HiddenSectionsContext)

  let journalFlags = GetSectionFlags(section.iconflags)
  let imagePath = undefined
  if (section.iconfile) {
    let fileName = section.iconfile.filepath.split('/').pop()?.split('.')[0].replace(/\s+/g, '-')
    imagePath = `https://wow.zamimg.com/images/wow/icons/large/${fileName}.jpg`
  }

  function handleCopy(text: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    handleCopyToClipboard(text, event)
    enqueueSnackbar(`Copied: [${text}] to clipboard`, { preventDuplicate: true })
  }

  return (
    <div
      className={`title_lang ${journalFlags.includes('Heroic') ? 'heroic-title' : ''} ${journalFlags.includes('Mythic') ? 'mythic-title' : ''} ${hiddenSections[section.id] ? 'inactive' : 'active'} ${parentHidden && 'hidden'}`}
      onClick={() => toggleHidden(section.id)}
      data-section-id={section.id}
    >
      {imagePath ? (
        <Tooltip key={`${imagePath}-${section.id}`} title={'Click to copy the iconId'} arrow TransitionComponent={Zoom}>
          <img
            className="spellIcon"
            src={imagePath}
            alt="icon"
            onClick={(event) => handleCopy(section.iconfile.id, event)}
          />
        </Tooltip>
      ) : null}
      {section.spellid !== 0 ? (
        <>
          <Tooltip
            key={`${section.title_lang}-${section.id}`}
            title={'Click to copy the spellname'}
            arrow
            TransitionComponent={Zoom}
          >
            <span className="fakeLink" onClick={(event) => handleCopy(section.title_lang, event)}>
              {section.title_lang}
            </span>
          </Tooltip>
          <Tooltip
            key={`${section.spellid}-${section.id}`}
            title={'Click to copy the spellId'}
            arrow
            TransitionComponent={Zoom}
          >
            <span className="spellIdCopy" onClick={(event) => handleCopy(section.spellid.toString(), event)}>
              ({section.spellid})
            </span>
          </Tooltip>
        </>
      ) : (
        <span className="fakeLink" onClick={(event) => handleCopy(section.title_lang, event)}>
          {section.title_lang}
        </span>
      )}
      <div className="iconFlags">
        {journalFlags.length > 0 &&
          journalFlags.map((tag) => (
            <Tooltip key={tag} title={tag} arrow TransitionComponent={Zoom}>
              <img key={tag} className={`icon_tag tag_${tag}`} src={`textures/${tag}_icon.png`} alt={String(tag)} />
            </Tooltip>
          ))}
        {section.spellid !== 0 && (
          <a
            data-disable-wowhead-tooltip="true"
            data-wh-rename-link="false"
            rel="noreferrer"
            target="_blank"
            href={`https://wowhead.com/ptr-2/spell=` + section.spellid}
            onClick={(event) => event.stopPropagation()}
          >
            <img className={`icon_tag tag_wowhead`} src={`wowhead-icon.png`} alt="wowhead icon" />
          </a>
        )}
      </div>
    </div>
  )
}

export default JournalSectionTitle
