import React, { useContext } from 'react'
import { HiddenSectionsContext } from '../../contexts/Context'

function CollapseButton({ sections }: { sections: JournalSection[] }) {
  const [hiddenSections, toggleHidden] = useContext(HiddenSectionsContext)
  let collapsed = false

  function checkCollapsedState(section: JournalSection): boolean {
    if (hiddenSections[section.id] === undefined || hiddenSections[section.id] === false) return false
    return true
  }

  function checkChildSections(section: JournalSection): boolean {
    if (checkCollapsedState(section)) {
      return true
    }
    if (section.child) {
      for (const sectionChild of section.child) {
        if (checkCollapsedState(sectionChild)) {
          return true
        }
      }
    }
    return false
  }

  for (const section of sections) {
    if (checkChildSections(section)) {
      collapsed = true
      break
    }
  }

  function collapse(section: JournalSection) {
    if (collapsed === false && hiddenSections[section.id] === false) {
      toggleHidden(section.id)
    } else if (collapsed === true && hiddenSections[section.id] === true) {
      toggleHidden(section.id)
    }
    section.child?.forEach((sectionChild: JournalSection) => {
      collapse(sectionChild)
    })
  }

  const clicked = () => {
    sections.forEach((section) => {
      collapse(section)
    })
  }

  return (
    <button id="CollapseButton" onClick={() => clicked()}>
      {collapsed ? 'Expand' : 'Collapse'}
    </button>
  )
}

export default CollapseButton
