import React, { useContext } from 'react'
import { HiddenSectionsContext } from '../../contexts/Context'

const CustomTitle = ({
  title,
  sections,
  sectionId,
}: {
  title: string
  sections: JournalSection[]
  sectionId: number
}) => {
  const [hiddenSections, toggleHidden] = useContext(HiddenSectionsContext)

  function collapse(section: JournalSection) {
    if (hiddenSections[section.id] === true) {
      toggleHidden(section.id)
    }
    section.child?.forEach((sectionChild: JournalSection) => {
      collapse(sectionChild)
    })
  }

  const clicked = () => {
    sections.forEach((section: JournalSection) => {
      collapse(section)
    })

    setTimeout(() => {
      const element = document.querySelector(`[data-section-id="${sectionId}"]`)
      const mainMenu = document.getElementById('mainMenu')
      if (element && mainMenu) {
        const offset = mainMenu.getBoundingClientRect().height
        element.scrollIntoView()
        window.scrollBy(0, -offset)
      }
    }, 1)
  }

  return (
    <span className="diffClickMe" onClick={() => clicked()}>
      {title}
    </span>
  )
}

export default CustomTitle
