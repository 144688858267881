import React, { useState, useEffect, useContext } from 'react'
import { diff } from 'deep-diff'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import CustomTitle from './CustomTitle' // Import the custom title component
import { BuildContext, CompareBuildContext, BossContext, DifficultyContext } from '../../contexts/Context'

function DiffView({ isLoading, journal }: { isLoading: boolean; journal: Journal }): any {
  const [compareBuild] = useContext(CompareBuildContext)
  const [activeBuild] = useContext(BuildContext)
  const [bossId] = useContext(BossContext)
  const [difficulty] = useContext(DifficultyContext)
  const [compareBuildData, setCompareBuildData] = useState<any[] | null>(null)

  function findSectionInfo(obj: any, path: string[]): [string, number] {
    let current: any = obj
    let parents: any[] = []
    let keys: string[] = []

    for (let i = 0; i < path.length; i++) {
      if (current[path[i]] === undefined) {
        return ['Unkown Title', 0]
      }
      parents.push(current)
      keys.push(path[i])
      current = current[path[i]]
    }

    while (parents.length > 0) {
      if (current.hasOwnProperty('title_lang')) {
        return [current.title_lang, current.id]
      }
      current = parents.pop()
      keys.pop()
    }
    return ['Unkown Title', 0]
  }

  useEffect(() => {
    setCompareBuildData(null)
    if (!compareBuild || isLoading) {
      return
    } else if (compareBuild === activeBuild) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + compareBuild + '/boss/' + bossId + '/' + difficulty)
      .then((response) => response.json())
      .then((data) => {
        function sortSpellFlags(obj: any) {
          for (let key in obj) {
            let value = obj[key]
            if (value.spellFlags) {
              value.spellFlags.sort((a: [number, string], b: [number, string]) => {
                if (a[1] === b[1]) {
                  return String(a[0]).localeCompare(String(b[0]))
                }
                return Number(a[1]) - Number(b[1])
              })
            }
            if (value.child) {
              sortSpellFlags(value.child)
            }
          }
        }

        sortSpellFlags(data.journalSection)
        sortSpellFlags(journal.journalSection)

        const differences = diff(journal, data, (path, key) => {
          if (path.includes('spellFlags')) {
            return key === 1
          }
          return ['buildguid', 'journalsectionxdifficulty'].includes(key)
        })
        interface Differences {
          lhs: string
          rhs: string
          nameLang: string
          sectionId?: number
        }
        if (differences) {
          const filteredDifferences = differences.filter((difference: any) => {
            const lhs = difference.lhs === undefined ? '' : difference.lhs
            const rhs = difference.rhs === undefined ? '' : difference.rhs
            return !(typeof lhs === 'number' || typeof rhs === 'number' || lhs === rhs) || difference.kind === 'A'
          })

          const mappedDifferences = filteredDifferences.map((difference: any): Differences => {
            const [nameLang, sectionId] = findSectionInfo(journal, difference.path as string[])
            if (difference.kind === 'E') {
              let lhs = difference.lhs.replace(/<br\s*\/?>/gi, '\n')
              let rhs = difference.rhs.replace(/<br\s*\/?>/gi, '\n')

              return {
                lhs: lhs || '',
                rhs: rhs || '',
                nameLang: nameLang,
                sectionId: sectionId,
              }
            } else if (difference.kind === 'A') {
              const kind = difference.item.kind
              return {
                lhs: kind === 'D' ? difference.item.lhs[0] : '',
                rhs: kind === 'N' ? difference.item.rhs[0] : '',
                nameLang: nameLang,
                sectionId: sectionId,
              }
            } else if (difference.kind === 'D') {
              return {
                lhs: difference.lhs[0].bodytext_lang,
                rhs: '',
                nameLang: `New: ${nameLang}`,
                sectionId: sectionId,
              }
            } else if (difference.kind === 'N') {
              return {
                lhs: '',
                rhs: difference.rhs[0].bodytext_lang,
                nameLang: `Deleted: ${nameLang}`,
                sectionId: sectionId,
              }
            }

            return {
              lhs: '',
              rhs: '',
              nameLang: 'Unkown Change, Kind: ' + difference.kind,
              sectionId: sectionId,
            }
          })
          const cleanedDifferences = mappedDifferences.map((difference: Differences) => {
            let lhs = typeof difference.lhs === 'string' ? difference.lhs.replace(/<[^>]*>/g, '') : difference.lhs
            let rhs = typeof difference.rhs === 'string' ? difference.rhs.replace(/<[^>]*>/g, '') : difference.rhs

            return {
              sectionId: difference.sectionId,
              nameLang: difference.nameLang,
              lhs: lhs,
              rhs: rhs,
            }
          })
          setCompareBuildData(cleanedDifferences)
        } else {
          setCompareBuildData([])
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [compareBuild, isLoading, activeBuild, bossId, difficulty, journal])

  const newDiffStyles = {
    variables: {
      dark: {
        diffViewerTitleColor: '#cad1d8',
        addedBackground: '#158996',
        wordAddedBackground: '#08a31d',
      },
    },
    titleBlock: {
      fontSize: '1.2em',
    },
  }

  return (
    <>
      {!isLoading && compareBuild ? (
        compareBuildData ? (
          <div id="comparedBuilds">
            {compareBuildData.map((entry: any, index: number) => (
              <ReactDiffViewer
                styles={newDiffStyles}
                key={index}
                oldValue={entry.rhs}
                newValue={entry.lhs}
                splitView={false}
                hideLineNumbers={true}
                useDarkTheme={true}
                // leftTitle="<a>ok</a>" //{entry.nameLang}
                leftTitle={
                  <CustomTitle title={entry.nameLang} sections={journal.journalSection} sectionId={entry.sectionId} />
                } // Use the custom title component
                showDiffOnly={true}
                extraLinesSurroundingDiff={0}
                compareMethod={DiffMethod.WORDS}
              />
            ))}
          </div>
        ) : (
          <div id="diffLoading">
            {compareBuild === activeBuild ? "You've got the same build selected. >_>" : 'Loading Comparison...'}
          </div>
        )
      ) : null}
    </>
  )
}

export default DiffView
